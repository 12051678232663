import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import './StripeContainer.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Stripe = (props) => {
    const cartValid = () => {
        console.log('Validation Stripe container');
        props.cartValid();
    }
    return(
        <Elements stripe={stripePromise}>
            <CheckoutForm
                shop_articles={props.shop_articles}
                top_articles={props.top_articles}
                shop_cart={props.shop_cart}
                top_cart={props.top_cart}
                user={props.user}
                total={props.total}
                taxes={props.taxes}
                taxes_tps={props.taxes_tps}
                taxes_tvq={props.taxes_tvq}
                cartValid = {cartValid}
                order_id = {props.order_id}
            />
        </Elements>
    );
};

export default Stripe;