import React from "react";
import "./BackOffice.css";
import axios from "axios";
import BOArticle from "./BOArticle/BOArticle";
import BOOrder from "./BOOrder/BOOrder";

class BackOffice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article_list: [],
      article_data_list: [],
      active_articles: [],
      orders: [],
      orders_source : [],
      article_id: null,
      article_local_id : null,
      article_active: 0,
      article_name: "",
      article_price: 0,
      article_step: 1,
      article_description: "",
      orderListStatus : 0,
      article_image: [],
      article_image_style : {backgroundImage : `url(https://chouxtime.com/server/image/default.jpg)`},
      mail: '',
      pass: '',
      connected : false,
      connect_alert : ''
    };
    this.activeArticle = this.activeArticle.bind(this);
    this.selectArticle = this.selectArticle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.articleList = this.articleList.bind(this);
    this.ordersList = this.ordersList.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.newArticle = this.newArticle.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
    this.validOrder = this.validOrder.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.checkArticle = this.checkArticle.bind(this);
    this.handleConnecChange = this.handleConnecChange.bind(this);
    this.validConnection = this.validConnection.bind(this);
  }
  componentDidMount(){
    this.articleList();
    this.ordersList(0)
  }
  async articleList(art_id=0) {
    try {
      const art_active = await axios.post(
        "https://chouxtime.com/api/articles_list",
        {
          id: this.state.article_id,
        }
      );
      if (art_active.data.success) {
        console.log("Liste article actualisée");
        var article_list = [];
        console.log(art_active.data);
        if(art_active.data.articles_row){
          article_list = art_active.data.articles_row.map((art, key) => {
            return (
              <BOArticle article={art} id={key} key={key} activeArticle={this.activeArticle} selectArticle={this.selectArticle}/>
            );
          });
          console.log(article_list);
          this.setState({
            article_list: article_list,
            article_data_list : art_active.data.articles_row
          }, () => {
            if(art_id !== 0){
              this.selectArticle(art_id);
            } else if(!this.state.article_id && article_list.length > 0){
              this.selectArticle(0);
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  async activeArticle(id, status) {
    const active_articles = this.state.active_articles;
    active_articles[id] = status;
    this.setState({ active_articles: active_articles });
    try {
      const art_active = await axios.post(
        "https://chouxtime.com/api/article_active",
        {
          article_id: this.state.article_data_list[id].id,
          active: status,
        }
      );
      if (art_active.data.success) {
          console.log("Article activé");
          this.articleList();
      }
    } catch (err) {
      console.log(err);
    }
  }
  selectArticle(id) {
    let article_image_style;
    console.log("article " + id + " sélectionné");
    if(this.state.article_data_list[id].pictures){
      console.log(this.state.article_data_list);
      article_image_style = {backgroundImage : `url(https://chouxtime.com/server/image/${this.state.article_data_list[id].pictures})`};
    } else {
      article_image_style = {backgroundImage : `url(https://chouxtime.com/server/image/default.jpg})`};
    }
    this.setState({
      article_local_id : id,
      article_id: this.state.article_data_list[id].id,
      article_name: this.state.article_data_list[id].name,
      article_price: this.state.article_data_list[id].price,
      article_step: this.state.article_data_list[id].step,
      article_description: this.state.article_data_list[id].description,
      article_image: this.state.article_data_list[id].pictures,
      article_active: this.state.article_data_list[id].active,
      article_image_style : article_image_style
    });
  }
  async ordersList(valid) {
    console.log('ORDERS LIST');
    try {
      const orders = await axios.post("https://chouxtime.com/api/orders_list", {valid : valid});
      if (orders.data.success) {
        console.log("Liste de commandes reçu");
        console.log(orders.data.ordersList);

        let ordersList = orders.data.ordersList.map(
          (order, key) => {
            return (
              <BOOrder
                date={order.order_date ? order.client_date : ""}
                id={order.id}
                created_at={order.created_at}
                order_date={order.order_date}
                client_name={order.client_name ? order.client_name : ""}
                client_surname={order.client_surname ? order.client_surname : ""}
                client_adress={order.client_address ? order.client_address : ""}
                client_zip={order.client_zip ? order.client_zip : ""}
                client_mail={order.client_mail ? order.client_mail : ""}
                client_phone={order.client_phone ? order.client_phone : ""}
                articles={order.articles ? JSON.parse(order.articles) : []}
                valid={order.valid ? order.valid : 0}
                key={key}
                checkArticle={this.checkArticle}
                validOrder={this.validOrder}
              ></BOOrder>
            );
          }
        );
        this.setState({
          orderListStatus : valid,
          orders: ordersList,
          orders_source: orders.data.ordersList,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  handleChange(e) {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      async () => {
        try {
          const art_change = await axios.post(
            "https://chouxtime.com/api/article_change",
            {
              id: this.state.article_id,
              name: this.state.article_name,
              price: this.state.article_price,
              step: this.state.article_step,
              description: this.state.article_description,
              images : this.state.article_image,
              active : this.state.article_active,
            }
          );
          if (art_change.data.success) {
            console.log("Article modifié");
            this.articleList();
          }
        } catch (err) {
          console.log(err);
        }
      }
    );
  }
  async checkArticle(id_order, id_art) {
    console.log("article checked");

    const orderIndex = this.state.orders_source.findIndex((elmnt) => {
      return elmnt.id === id_order;
    });
    console.log(this.state.orders_source);
    console.log(orderIndex);
    console.log(id_order);
    let order_article;
    let orders = this.state.orders_source;
    if(!Array.isArray(orders[orderIndex]["articles"]))
      order_article = JSON.parse(orders[orderIndex]["articles"])
    else 
      order_article = orders[orderIndex]["articles"];
    
    let order;
    
    console.log(orders[orderIndex]["articles"]);
    order_article[id_art]["status"] = order_article[id_art]["status"] === 0 ? 1 : 0;
    orders[orderIndex]["articles"] = order_article;
    order = orders[orderIndex];

    try {
      const art_active = await axios.post(
        "https://chouxtime.com/api/article_check",
        {
          id_order: id_order,
          order_articles: order.articles
        }
      );
      if (art_active.data.success) {
        console.log("Article activé");
      }
    } catch (err) {
      console.log(err);
    }
  }
  async newArticle() {
    console.log("nouvel article");
    try {
      const new_art = await axios.post(
        "https://chouxtime.com/api/bo_new_article"
      );
      if (new_art.data.success) {
        console.log("Nouvel article créé");
        this.setState({
            article_id : new_art.data.id,
            article_name : "",
            article_price : 0,
            article_step : 1,
            article_description : "",
            article_image : [],
            article_image_data : [],
        }, () => this.articleList(this.state.article_data_list.length));
      }
    } catch (err) {
      console.log(err);
    }
  }
  async deleteImage(id_img) {
    console.log("image supprimée");

    let article_image = this.state.article_image;
    for(let i = 0; i > article_image.length; i++){
        if(i >= id_img && i < article_image.length - 1){
            article_image[i] = article_image[i + 1];
        }
    }

    try {
        const del_img = await axios.post(
          "https://chouxtime.com/api/bo_delete_image",
          {
            id : this.state.article_id,
            images : article_image
          }
        );
        if (del_img.data.success) {
          console.log("Image supprimé !");
          this.setState({
              article_image : article_image
          });
        }
      } catch (err) {
        console.log(err);
      }
  }
  async deleteArticle() {
    try {
        const del_art = await axios.post(
          "https://chouxtime.com/api/bo_delete_article",
          {
            id : this.state.article_id,
          }
        );
        if (del_art.data.success) {
            console.log('article supprimé avec succès');
            this.setState({article_id : null});
            this.articleList();
        }
      } catch (err) {
        console.log(err);
      }
  }
  async validOrder(id) {
    console.log("commande " + id + " validée");
    try {
        const del_art = await axios.post(
          "https://chouxtime.com/api/bo_valid_order",
          {
            id : id
          }
        );
        if (del_art.data.success) {
            console.log('commande ' + id + ' validée avec succès');
            this.ordersList(0);
        }
    } catch (err) {
        console.log(err);
    }
  }
  uploadImage(e) {
    console.log('FILE => Lancement téléchargement fichier');
    //var imageIcons = this.state.article.imageIcons;
    const file = e.target.files[0];

    console.log(this.state.article_id);

    const formData = new FormData();
    formData.append('art_id', this.state.article_id);
    formData.append('file', file);

        axios.post("https://chouxtime.com/api/bo_upload_image", formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          console.log(response.data);

          let indexArticle = this.state.article_data_list.map(e => e.id).indexOf(this.state.article_id);

          //this.selectArticle(indexArticle);
          this.articleList(this.state.article_active);
        })
        .catch(error => {
          console.log(error);
        });
  }
  handleConnecChange(e){
    this.setState({
      [e.target.id] : e.target.value
    })
  }
  validConnection(){
    if (this.state.mail === process.env.REACT_APP_BO_MAIL && this.state.pass === process.env.REACT_APP_BO_PASS){
      this.setState({
        connected : true,
        connect_alert : ''
      })
    } else {
      this.setState({
        connect_alert : "Identifiants erronés"
      })
    }
  }
  render() {
    if(this.state.connected === true && this.state.mail === process.env.REACT_APP_BO_MAIL && this.state.pass === process.env.REACT_APP_BO_PASS){
      return (
        <div className="back_office">
          <div className="articles_list">
            <div className="articles_list_block">
              {this.state.article_list}
            </div>
            <button
              className="btn btn-dark article_create_button"
              onClick={this.newArticle}
            >
              Nouvel article
            </button>
          </div>
          <div className="article_zone">
            <div className="article_zone_create">
              {/* Nom du Produit */}
              <div className="form-floating mb-3">
                <input
                  id="article_name"
                  type="text"
                  className="contact_input form-control"
                  placeholder="Nom du produit"
                  onChange={this.handleChange}
                  onBlur={this.sendArticleToServer}
                  value={this.state.article_name}
                ></input>
                <label htmlFor="article_name">Nom du produit</label>
              </div>
              {/* Prix du Produit */}
              <div className="form-floating mb-3">
                <input
                  id="article_price"
                  type="number"
                  className="contact_input form-control"
                  placeholder="Prix HT"
                  onChange={this.handleChange}
                  onBlur={this.sendArticleToServer}
                  value={this.state.article_price}
                ></input>
                <label htmlFor="article_price">Prix HT</label>
              </div>
              {/* Description du Produit */}
              <div className="form-floating mb-3">
                <input
                  id="article_description"
                  type="text"
                  className="contact_input form-control"
                  placeholder="Description du produit"
                  onChange={this.handleChange}
                  onBlur={this.sendArticleToServer}
                  value={this.state.article_description}
                ></input>
                <label htmlFor="article_description">
                  Description du produit
                </label>
              </div>

              {/* Lots du Produit */}
              <div className="form-floating mb-3">
                <input
                  id="article_step"
                  type="number"
                  className="contact_input form-control"
                  placeholder="Vendu par lot de"
                  onChange={this.handleChange}
                  onBlur={this.sendArticleToServer}
                  value={this.state.article_step}
                ></input>
                <label htmlFor="article_step">Vendu par lot de</label>
              </div>

              <div className="bo_article_image_zone">
                <div className="bo_article_images">
                  <div className="bo_article_image" style={this.state.article_image_style}>
                    <div
                      className="bo_article_image_delete btn btn-danger"
                      onClick={() => this.deleteImage(0)}
                    >
                      Supprimer
                    </div>
                  </div>
                </div>
                <input type="file" onChange={this.uploadImage}></input>
              </div>

              <button className="btn btn-danger" onClick={this.deleteArticle}>
                Supprimer l'article
              </button>
            </div>
          </div>
          <div className="order_zone">{this.state.orders}</div>
        </div>
      );
    } else {
      return (
        <div className="back_office green_background">
          <div className='back_office_connec'>
            {/* Description du Produit */}
            <h2>Connexion</h2>
            <div className="form-floating mb-3">
              <input id="mail" type="email" className="contact_input form-control" placeholder="Adresse mail" onChange={this.handleConnecChange}></input>
              <label htmlFor="article_description">Adresse mail</label>
            </div>
            <div className="form-floating mb-3">
              <input id="pass" type="pass" className="contact_input form-control" placeholder="Mot de passe" onChange={this.handleConnecChange}></input>
              <label htmlFor="article_description">Mot de passe</label>
            </div>
            <div className={this.state.connect_alert !== '' ? "alert alert-danger" : "alert bo_article_image_delete"}>{this.state.connect_alert}</div>
            <button className="btn btn-dark" onClick={this.validConnection}>Valider</button>
          </div>
        </div>
      )
    }
  }
}

export default BackOffice;
