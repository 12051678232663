import React from "react";
import "./ShopArticle.css";

class ShopArticle extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            id : 0,
            name : 'Titre',
            desc : 'Description',
            price : '0.00',
            img : '',
            status : 0,
            wish: 0,
            number : 1
        }
        this.handleCart = this.handleCart.bind(this);
        this.handleWish = this.handleWish.bind(this);
        this.handleNumber = this.handleNumber.bind(this);
    }
    componentDidMount(){
        console.log('mount');
        this.setState({
            id : this.props.id,
            name : this.props.name,
            desc : this.props.desc,
            price : this.props.price,
            img : this.props.img,
            wish : this.props.wish,
            status : 0,
            number : this.props.number === 0 ? 1 : this.props.number
        });
        this.handleWish = this.handleWish.bind(this);
        this.handleNumber = this.handleNumber.bind(this);
        this.handleCart = this.handleCart.bind(this);
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps !== this.props){
            this.setState({
                id : this.props.id,
                name : this.props.name,
                desc : this.props.desc,
                price : this.props.price,
                wish : this.props.wish,
                status : this.props.number === 0 ? 0 : prevState.status,
                number : this.props.number === 0 ? 1 : this.props.number
            });
        }
    }
    handleWish() {
        this.props.wishAdd(this.state.id, this.state.wish);
        this.state.wish === 0 ? this.setState({wish : 1}) : this.setState({wish : 0});
    }
    handleNumber(e) {
        var art_number = parseInt(e.target.value);

        if(this.state.status !== 0 && art_number !== 0){
            this.props.cartAdd(this.state.id, art_number, 0);
            this.setState({number : e.target.value});
        } else if(this.state.status !== 0 && art_number === 0){
            this.props.cartAdd(this.state.id, 1, 1);
            this.setState({number : 1, status: 0});
        } else if (this.state.status === 0 && art_number === 0){
            this.setState({number : 1, status: 0});
        } else {
            this.setState({number : art_number});
        }
    }
    handleCart() {
        if(this.state.status === 0){
            this.props.cartAdd(this.state.id, this.state.number, this.state.status);
            this.setState({status : 1});
        } else {
            this.props.cartAdd(this.state.id, 0, 0);
            this.setState({status : 0, number : 1});
        }
    }
    render() {
        var art_image = {backgroundImage : `url("https://chouxtime.com/server/image/${this.props.img}")`};
        return(
            <div className='shop_article card'>
                <div style={art_image} className='shop_article_image card-img-top ratio ratio-1x1' alt='Choux'></div>
                <div className='card-body'>
                    <h5 className="card-title">{this.state.name}</h5>
                    <p className="card-text">{this.state.desc}</p>
                    <div className='buy_tool_bar'>
                        <span className={this.state.wish === 0 ? 'wish_add_button btn btn-light' : 'wish_add_button btn btn-danger'} onClick={this.handleWish}>	&#x2764; </span>
                        <span className="shop_article_price">{this.state.price} $</span>
                        <input type='number' className='article_number_input' value={this.state.number} onChange={this.handleNumber}></input>
                        <span className={this.state.status === 0 ? 'cart_add_button btn btn-light' : 'cart_add_button btn btn-dark'} onClick={this.handleCart}>+</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShopArticle;
