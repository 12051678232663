import React from "react";
import './ArticleCartLine.css';

class ArticleCartLine extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            type : 0,
            id : 0,
            number : 0,
            name : "",
            price : 0.00,
            img : ''
        }
        this.numberHandle = this.numberHandle.bind(this);
    }
    componentDidMount(){
        this.setState({
            id : this.props.id,
            type : this.props.type,
            number : this.props.number,
            name : this.props.name,
            price : this.props.price,
            img : this.props.img
        })
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            this.setState({
                id : this.props.id,
                type : this.props.type,
                number : this.props.number,
                name : this.props.name,
                price : this.props.price,
                img : this.props.img
            })
        }
    }
    numberHandle(e){
        this.setState({number : e.target.value}, this.props.numberChange(this.state.type, this.state.id, e.target.value))
    }
    render() {
        var art_image = {backgroundImage : `url("https://chouxtime.com/server/image/${this.props.img}")`};
        return(
            <div className='ArticleCartLine'>
                <div style={art_image} alt='cart art' className="article_cart_line_img ratio ratio-1x1"></div>
                <span className="article_cart_line_title">{this.state.name}</span>
                <input type='number' className='article_number_input' value={this.state.number} onChange={this.numberHandle}></input>
                <span className='article_cart_line_price'>{this.state.price} $</span>
            </div>
        )
    }
}

export default ArticleCartLine;