import React from "react";
import ArticleCartLine from "./ArticleCartLine/ArticleCartLine";
import CartCover from "./CartCover/CartCover";
import CartDelivery from "./CartDelivery/CartDelivery";
import CartPaiement from "./CartPaiement/CartPaiement";
import CartAccept from "./CartAccept/CartAccept";
import axios from "axios";

import "./Cart.css";

class Cart extends React.Component{
    constructor (props) {
        super(props);
        this.state = {
            order_id : 0,
            taux : 0.2,
            taxes : 0,
            taxes_tps : 0,
            taxes_tvq : 0,
            total : 0,
            location : false,
            next_button : false,
            shop_articles : [],
            top_articles : [],
            shop_cart : [],
            shop_wish : [],
            top_cart : [],
            top_wish : [],
            cart_articles : [],
            top_cart_articles : [],
            zip : "",
            surname : "",
            name : "",
            address : "",
            address_bis : "",
            tel : "",
            mail : "",
            active_hour : null,
            cart_state : <CartCover></CartCover>,
            cart_step : 0,
            active_hour : 0,
            eligible : false,
            back_button : <button type="button" className="btn btn-secondary" data-bs-dismiss='modal'>Continuer mes achats</button>,
            alert : ''
        }
        this.articleLineCreate = this.articleLineCreate.bind(this);
        this.numberChange = this.numberChange.bind(this);
        this.cartValid = this.cartValid.bind(this);
        this.CartBack = this.CartBack.bind(this);
        this.changeHour = this.changeHour.bind(this);
        this.handleEligible = this.handleEligible.bind(this);
        this.delivery_valid = this.delivery_valid.bind(this);
        this.orderDataSend = this.orderDataSend.bind(this);
    }
    componentDidMount(){
        let next_button = false;
        if(this.state.cart_step === 0 && this.state.total > 0 && this.state.eligible) next_button = true;
        else if(this.state.cart_step === 1 && this.state.location) next_button = true;

        this.setState({
            shop_articles : this.props.shop_articles,
            top_articles : this.props.top_articles,
            shop_cart : this.props.shop_cart,
            shop_wish : this.props.shop_wish,
            top_cart : this.props.top_cart,
            top_wish : this.props.top_wish,
            next_button : next_button,
            cart_state : <CartCover top_cart_articles={this.state.top_cart_articles} cart_articles={this.state.cart_articles} total={this.state.total} taxes={this.state.taxes} numberChange={this.numberChange} shop_cart={this.state.shop_cart} top_cart={this.state.top_cart} handleEligible={this.handleEligible}></CartCover>
        }, this.articleLineCreate());
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            console.log('Cart actualise');
            let next_button = false;
            if(this.state.cart_step === 0 && this.state.total > 0 && this.state.eligible) next_button = true;
            else if(this.state.cart_step === 1 && this.state.location) next_button = true;
            this.setState({
                shop_articles : this.props.shop_articles,
                top_articles : this.props.top_articles,
                shop_cart : this.props.shop_cart,
                shop_wish : this.props.shop_wish,
                top_cart : this.props.top_cart,
                top_wish : this.props.top_wish,
                next_button : next_button,
                alert : ''
            }, this.articleLineCreate());
        }
    }
    articleLineCreate(){
        var total = 0;
        var taxes = 0;
        var taxes_tps = 0;
        var taxes_tvq = 0;
        console.log(this.state.shop_articles);
        var cart_articles = this.state.shop_articles.map((article, art_i) => {
            if(this.state.shop_cart[art_i] !== 0){
                console.log('shop');
                total += article.price * this.state.shop_cart[art_i];
                if(article.step < 6 ){
                    taxes_tps += article.price * this.state.shop_cart[art_i] * 0.0975;
                    taxes_tvq += article.price * this.state.shop_cart[art_i] * 0.5;
                }
                return <ArticleCartLine name={article.name} img={article.img} type='0' price={article.price} number={this.state.shop_cart[art_i]} id={art_i} key={art_i} cartAdd={this.cartAdd} wishAdd={this.wishAdd} numberChange={this.numberChange}/>
            } else {
                return '';
            }
        });
        var top_cart_articles = this.state.top_articles.map((article, art_i) => {
            if(this.state.top_cart[art_i] !== 0){
                console.log('top');
                total += article.price * this.state.top_cart[art_i];
                if(article.step < 6 ){
                    taxes_tps += article.price * this.state.top_cart[art_i] * 0.09975;
                    taxes_tvq += article.price * this.state.top_cart[art_i] * 0.5;
                }
                return <ArticleCartLine name={article.name} img={article.img} type='1' price={article.price} number={this.state.top_cart[art_i]} id={art_i} key={art_i} cartAdd={this.cartAdd} wishAdd={this.wishAdd} numberChange={this.numberChange}/>;
            } else {
                return '';
            }
        });
        taxes = taxes_tps + taxes_tvq;
        total = total + taxes;
        this.setState({
            total : total,
            taxes : taxes,
            taxes_tps : taxes_tps,
            taxes_tvq : taxes_tvq,
            cart_articles : cart_articles,
            top_cart_articles : top_cart_articles,
            cart_state : <CartCover top_cart_articles={top_cart_articles} cart_articles={cart_articles} total={total} taxes={taxes} taxes_tps={taxes_tps} taxes_tvq={taxes_tvq} numberChange={this.numberChange} shop_cart={this.state.shop_cart} top_cart={this.state.top_cart} handleEligible={this.handleEligible}></CartCover>
        });
    }
    numberChange(type, id, number) {
        let cart;
        if(parseInt(type) === 1){
            cart = this.state.top_cart;
            cart[id] = parseInt(number);
            this.setState({top_cart : cart}, () => {
                if(parseInt(number) === 0)
                    this.articleLineCreate();
                this.props.numberChange(parseInt(type), parseInt(id), parseInt(number));
            });
        } else if (parseInt(type) === 0) {
            cart = this.state.shop_cart;
            cart[id] = parseInt(number);
            this.setState({shop_cart : cart}, () => {
                if(parseInt(number) === 0)
                    this.articleLineCreate();
                this.props.numberChange(parseInt(type), parseInt(id), parseInt(number));
            });
        }
    }
    delivery_valid(name, surname, address, address_bis, tel, mail, active_hour){
        this.setState({
            location : true,
            next_button : true,
            name : name,
            surname : surname,
            address : address,
            address_bis : address_bis,
            tel : tel,
            mail : mail,
            active_hour : active_hour
        });
    }
    async orderDataSend(step){
        let stepData;
        console.log('order send - step : ' + step);
        if(step === 0){
            stepData = {
                shop_articles : this.state.shop_articles,
                top_articles : this.state.top_articles,
                shop_cart : this.state.shop_cart,
                top_cart : this.state.top_cart,
                zip : this.state.zip,
                total : this.state.total
            }
            this.props.cartNextStep(0, {
                zip : this.state.zip,
                total : this.state.total
            })
        } else if (step === 1) {
            stepData = {
                surname : this.state.surname,
                name : this.state.name,
                address : this.state.address,
                address_bis : this.state.address_bis,
                phone : this.state.tel,
                mail : this.state.mail,
                hour : this.state.active_hour
            }
            this.props.cartNextStep(1, stepData);
        }
        try {
            const orderSave = await axios.post(
                "https://chouxtime.com/api/order_enter",
                {
                  id: this.state.order_id,
                  step : step,
                  data : stepData
                }
            );
            if (orderSave.data.success) {
                if(this.state.order_id === 0){
                    this.setState({order_id : orderSave.data.id});
                }
                console.log("order updated");
            }
        } catch (error) {
            console.log(error);
        }
    }
    cartValid(order=false){
        if(this.state.next_button || order){
            this.setState({alert : ''});
            if(this.state.cart_step === 0){
                this.setState({
                    cart_state : <CartDelivery changeHour={this.changeHour} delivery_valid={this.delivery_valid}></CartDelivery>, 
                    cart_step : 1,
                    next_button : false,
                    back_button : <button type="button" className="btn btn-secondary" onClick={this.CartBack}>Retour</button>          
                }, () => this.orderDataSend(0));
            } else if(this.state.cart_step === 1){
                let user = {
                    name : this.state.name, 
                    surname : this.state.surname,
                    zip : this.state.zip,
                    address : this.state.address,
                    address_bis : this.state.address_bis,
                    tel : this.state.tel,
                    mail : this.state.mail
                }
                console.log(this.state.total);
                this.setState({
                    cart_state : <CartPaiement
                        shop_articles={this.state.shop_articles}
                        top_articles={this.state.top_articles}
                        shop_cart={this.state.shop_cart}
                        top_cart={this.state.top_cart}
                        taxes={this.state.taxes}
                        taxes_tps={this.state.taxes_tps}
                        taxes_tvq={this.state.taxes_tvq}
                        user={user} 
                        total={this.state.total}
                        order_id={this.state.order_id}
                        cartValid={() => this.cartValid(true)}
                    ></CartPaiement>,
                    cart_step : 2,
                    next_button : false,
                    back_button : <button type="button" className="btn btn-secondary" onClick={this.CartBack}>Retour</button>
                }, () => this.orderDataSend(1));
            } else if (this.state.cart_step === 2) {
                console.log('Cart Valide');
                this.setState({
                    cart_state : <CartAccept
                        shop_articles={this.state.shop_articles}
                        top_articles={this.state.top_articles}
                        shop_cart={this.state.shop_cart}
                        top_cart={this.state.top_cart}
                    ></CartAccept>, 
                    cart_step : 3,
                    next_button : true,
                    back_button : ""
                });
            } else if (this.state.cart_step === 3) {
                console.log("cart_step step 3");
                // TODO Vider le panier et revenir à étape 1
                // Conserver l'adresse et les info client.
            }
        } else if(this.state.total === 0){
            this.setState({alert : 'Veuillez sélectionner un article avant de valider votre panier'});
        } else if (this.state.eligible === false){
            this.setState({alert : 'Veuillez rentre un code postal éligible avant de valider votre commande'});
        }
    }
    CartBack () {
        if(this.state.cart_step > 1){
            var cart_step = this.state.cart_step - 2;
            this.setState({cart_step : cart_step}, () => this.cartValid());
            console.log(cart_step);
        } else {
            this.setState({
                cart_step : 0,
                cart_state : <CartCover top_cart_articles={this.state.top_cart_articles} cart_articles={this.state.cart_articles} total={this.state.total} taxes={this.state.taxes} numberChange={this.numberChange} shop_cart={this.state.shop_cart} top_cart={this.state.top_cart} handleEligible={this.handleEligible}></CartCover>,
                back_button : <button type="button" className="btn btn-secondary" data-bs-dismiss='modal'>Continuer mes achats</button>
            });
            console.log(cart_step);
        }
    }
    changeHour(id) {
        this.setState({active_hour : id});
    }
    handleEligible(eligible, zip){
        let next_button = false;

        if(this.state.total > 0 && eligible) next_button = true;
        this.setState({eligible : eligible, zip : zip, next_button : next_button});
        console.log("eligible : " + eligible);
    }
    render () {
        return(
            <div className="modal fade" id="cartBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header bg-light">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Panier</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {this.state.cart_state}
                        <div className={this.state.alert !== '' ? 'alert alert-danger' : 'alert'}>
                            {this.state.alert}
                        </div>
                    </div>
                    <div className="modal-footer">
                        {this.state.back_button}
                        <button type="button" className={this.state.next_button ? "btn btn-primary" : "btn btn-light"}  data-bs-dismiss={this.state.cart_step === 3 ? "modal" : ""} onClick={this.cartValid}>{this.state.cart_step === 0 ? 'Valider mon panier' : this.state.cart_step === 3 ? 'Terminer' : 'Continuer'}</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart;