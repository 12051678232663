import React from "react";

class BOArticle extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            id : null,
            active : false,
            name : ''
        }
        this.activeArticle = this.activeArticle.bind(this);
        this.selectArticle = this.selectArticle.bind(this);
    }
    componentDidMount(){
        this.setState({
            id : this.props.id,
            active : this.props.article.active,
            name : this.props.article.name
        })
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            this.setState({
                id : this.props.id,
                active : this.props.article.active,
                name : this.props.article.name
            })
        }
    }
    activeArticle(e){
        this.setState({active : e.target.checked}, () => this.props.activeArticle(this.state.id, e.target.checked));
    }
    selectArticle(){
        this.props.selectArticle(this.state.id);
    }
    render () {
        return(
            <div class='article_list_line'>
                <span className='article_list_line_name' onClick={this.selectArticle}>{this.state.name}</span>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" checked={this.state.active} id="flexSwitchCheckDefault" onChange={this.activeArticle}/>
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{this.state.active ? "actif" : "inactif"}</label>
                </div>               
            </div>
        )
    }
}

export default BOArticle;