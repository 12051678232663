import React from "react";

class BOOrder extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            id : 0,
            date : '',
            delivery_time : 0,
            valid : 0,
            client_name : '',
            client_surname : '',
            client_adress : '',
            client_adress_bis : '',
            client_phone : '',
            client_mail : '',
            client_zip : '',
            articles : [],
            art_check : []
        }
        this.validOrder = this.validOrder.bind(this);
        this.checkArticle = this.checkArticle.bind(this);
        this.orderActualise = this.orderActualise.bind(this);
    }
    componentDidMount(){
        this.orderActualise();
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            this.orderActualise();
        }
    }
    orderActualise(){
        const prop_articles = this.props.articles;
        let art_check = [];
        const articles = prop_articles.map((art, key) => {
            art_check[key] = art.status; 
            return (
                <li className='order_day_article_line' key={key}>
                    <span>{art.nombre}</span>
                    <span>{art.name}</span>
                    <input type='checkbox' checked={art.status} onChange={() => this.checkArticle(key)}></input>
                </li>
            )
        });
        this.setState({
            valid : this.props.valid,
            id : this.props.id,
            date : this.props.created_at,
            delivery_time : this.props.order_date,
            client_name : this.props.client_name,
            client_surname : this.props.client_surname,
            client_adress : this.props.client_adress,
            client_phone : this.props.client_phone,
            client_mail : this.props.client_mail,
            client_zip : this.props.client_zip,
            articles : articles,
            art_check : art_check
        });
    }
    checkArticle(id){
        let art_checker = this.state.art_check;
        art_checker[id] = art_checker[id] === 0 ? 1 : 0;
        let check_test = 1;

        const prop_articles = this.props.articles;
        const articles = prop_articles.map((art, key) => {
            console.log(art_checker[key]);
            if(art_checker[key] === 0) check_test = 0;
            return (
                <li className='order_day_article_line' key={key}>
                    <span>{art.nombre}</span>
                    <span>{art.name}</span>
                    <input type='checkbox' checked={art_checker[key]} onChange={() => this.checkArticle(key)}></input>
                </li>
            )
        });
        
        console.log(art_checker);

        this.setState({
            art_check : art_checker,
            articles : articles
        }, () => {
            console.log(this.state.id + " - " + id)
            if(check_test === 0)
                this.props.checkArticle(this.state.id, id);
            else
                this.props.validOrder(this.state.id);   
        });
    }
    componentDidUpdate(prevProps){
        if(this.props.articles !== prevProps.articles){
            const prop_articles = this.props.articles;
            const articles = prop_articles.map((art, key) => {
                return (
                    <li className='order_day_article_line' key={key}>
                        <span>{art.nombre}</span>
                        <span>{art.name}</span>
                        <input type='checkbox' onChange={() => this.checkArticle(key)}></input>
                    </li>
                )
            });
            this.setState({articles : articles});
        }
    }
    validOrder(){
        this.props.validOrder(this.state.id);
    }
    render () {
        var theDay = new Date(this.state.date);
        var today = new Date();
        today.setHours(0,0,0,0);
        var tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        var dayColor;

        if(theDay >= today && theDay < tomorrow){
            dayColor = {backgroundColor : '#cfe2f3'}
        } else if (theDay < today){
            dayColor = {backgroundColor : '#f1be9f'}
        } else if (theDay >= tomorrow){
            dayColor = {backgroundColor : '#d9ead3'}
        }

        if(theDay )
        return (
            <div className='order_day_block' style={dayColor}>
                <div className='order_day_date'>{theDay.getDate() + '/' + (theDay.getMonth() + 1) + '/' + theDay.getFullYear()}</div>
                <div className='order_day_articles'>
                    <ul>
                        <li className='order_day_client_line'><span>{this.state.client_name} {this.state.client_surname}</span></li>
                        {this.state.articles}
                    </ul>
                </div>
                <div className="order_valid_button btn btn-light" onClick={this.validOrder}>Terminé</div>
            </div>
        )
    }
}
export default BOOrder;