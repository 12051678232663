import React from "react";
import "./Footer.css"

class Footer extends React.Component{
    render () {
        return (
            <div className='footer container-fluidz'>
                Choux Time © 2022 - Mentions Légales
            </div>
        )
    }
}

export default Footer;