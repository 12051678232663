import React from "react";
import ShopArticle from "../ShopArticle/ShopArticle";
import "./ShopTop.css";

class ShopTop extends React.Component{
    constructor (props) {
        super(props);
        this.state = {
            articles : [],
            shop_articles : [],
            shop_state : {
                cart : [],
                wish : []
            }
        }
        this.cartAdd = this.cartAdd.bind(this);
        this.wishAdd = this.wishAdd.bind(this);
        this.articleRegister = this.articleRegister.bind(this);
    }
    componentDidMount() {
        var shop_state = {};
        shop_state.cart = this.props.cart;
        shop_state.wish = this.props.wish;

        this.setState({
            articles : this.props.articles,
            shop_state : shop_state
        }, () => this.articleRegister());
    }
    componentDidUpdate(prevProps){
        var shop_state = {};
        shop_state.cart = this.props.cart;
        shop_state.wish = this.props.wish;

        if(prevProps !== this.props){
            this.setState({
                articles : this.props.articles,
                shop_state : shop_state
            }, () => this.articleRegister());
        }
    }
    articleRegister(){
        var shop_articles = this.state.articles.map((article, art_i) => 
            <ShopArticle name={article.name} img={article.img} desc={article.desc} number={this.state.shop_state.cart[art_i]}  wish={this.state.shop_state.wish[art_i]} price={article.price} id={art_i} key={art_i} cartAdd={this.cartAdd} wishAdd={this.wishAdd} />
        );
        this.setState({shop_articles : shop_articles});
    }
    cartAdd(id, nbr, status){
        var shop_state = this.state.shop_state;
        if(status === 0) {
            shop_state.cart[id] = nbr;
        } else {
            shop_state.cart[id] = 0;
        }
        this.setState({shop_state : shop_state}, this.props.cartAdd(0, id, nbr));
    }
    wishAdd(id, status){
        var shop_state = this.state.shop_state;
        if(status === 0) {
            shop_state.wish[id] = 1;
        } else {
            shop_state.wish[id] = 0;
        }
        this.setState({shop_state : shop_state}, this.props.cartAdd(0, id, status));
    }
    render() {
        return(
            <div className='ShopTop container-fluid' id='productoftheweek'>
                <div className="container">
                    <h3>Choux Vedettes</h3>
                    <div className="ShopTopArticles">
                        <div className="ShopTopText ">
                            <h4 className="card-title">Vos petits favoris</h4>
                            <p className="card-text">
                                Les choux que vous aimez le plus, ceux qu'on a du mettre sur le podium pour que vous les trouviez au premier coup d'oeil !
                            </p>
                        </div>

                        {this.state.shop_articles}
                    </div>
                </div>
            </div>
        )
    }
}

export default ShopTop;
