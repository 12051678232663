import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Menu from './Menu/Menu';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import React from 'react';
import axios from "axios";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shop_articles : [
        {
            name : "Madeleines (x2)",
            desc : 'Description en quelques mots',
            price : '2.88',
            category : 'choux',
            id : 'price_1M7ca7GCwwPpVWaunMkADhv1',
            img : 'madeleines_x2.jpg'
        },
        {
            name : "Madeleines (x10)",
            desc : 'Description en quelques mots',
            price : '10.00',
            category : 'choux',
            img : 'madeleines_10.jpg'
        },
        {
            name : "Cookies (x2)",
            desc : 'Description en quelques mots',
            price : '4.60',
            category : 'choux',
            img : 'cookies_x2.jpg'
        },
        {
            name : "Cookies (x10)",
            desc : 'Description en quelques mots',
            price : '17.19',
            category : 'éclair',
            img : 'cookie_x2_pecan.jpg'
        },
        {
            name : "Cabosse individuelle",
            desc : 'Description en quelques mots',
            price : '9.43',
            category : 'choux',
            img : 'cabosse.jpg'
        },
        {
          name : "Cabosse (8 personnes)",
          desc : 'Description en quelques mots',
          price : '65.50',
          category : 'choux',
          img : 'cabosse.jpg'
        },
        {
          name : "Tartelette aux fraises",
          desc : 'Description en quelques mots',
          price : '8.05',
          category : 'choux',
          img : 'tartelette.jpg'
        },
        {
          name : "Tarte aux fraises (8 personnes)",
          desc : 'Description en quelques mots',
          price : '34.95',
          category : 'choux',
          img : 'tarte.jpg'
        },
        {
          name : "Rocher praliné",
          desc : 'Description en quelques mots',
          price : '8.63',
          category : 'choux',
          img : 'rocher.jpg'
        }
      ],
      top_articles : [
          {
              name : "Gros choux",
              desc : 'Description en quelques mots',
              price : '5.69',
              img : 'gros_choux.jpg'
          },
          {
              name : "Petit choux",
              desc : 'Description en quelques mots',
              price : '2.30',
              img : 'choux.jpg'
          },
          {
              name : "Éclair",
              desc : 'Description en quelques mots',
              price : '8.50',
              img : 'eclair.jpg'
          }
      ],
      shop_cart : [0, 0, 0, 0, 0, 0, 0, 0, 0],
      shop_wish : [0, 0, 0, 0, 0, 0, 0, 0, 0],
      top_cart : [0, 0, 0],
      top_wish : [0, 0, 0],
      zip : "",
      total : 0
    }
    this.cartAdd = this.cartAdd.bind(this);
    this.wishAdd = this.wishAdd.bind(this);
    this.cartNumberChange = this.cartNumberChange.bind(this);
    this.cartNextStep = this.cartNextStep.bind(this);
    this.actualiseArticles = this.actualiseArticles.bind(this);
  }
  componentDidMount(){
    console.log('App mount');
    this.actualiseArticles();
  }
  async actualiseArticles(){
    try {
      const art_active = await axios.post(
        "https://chouxtime.com/api/articles_list"
      );
      if(art_active.data.success){
        var shop_articles = [];
        var top_articles = [];
        for(let i = 0; i < art_active.data.articles_row.length; i++){
          if(i <= art_active.data.articles_row.length - 4 && art_active.data.articles_row[i].active === 1){
            shop_articles.push({
                name : art_active.data.articles_row[i].name,
                desc : art_active.data.articles_row[i].description,
                price : art_active.data.articles_row[i].price,
                step: art_active.data.articles_row[i].step,
                category : "choux",
                id : art_active.data.articles_row[i].id,
                img : art_active.data.articles_row[i].pictures !== "" ? art_active.data.articles_row[i].pictures : "default.jpg"
              })
          } else if (art_active.data.articles_row[i].active === 1) {
            top_articles.push({
              name : art_active.data.articles_row[i].name,
              desc : art_active.data.articles_row[i].description,
              price : art_active.data.articles_row[i].price,
              step: art_active.data.articles_row[i].step,
              category : "choux",
              id : art_active.data.articles_row[i].id,
              img : art_active.data.articles_row[i].pictures !== "" ? art_active.data.articles_row[i].pictures : "default.jpg"
            })
          }
          console.log(top_articles)
          console.log(shop_articles)
          this.setState({
            shop_articles : shop_articles,
            top_articles : top_articles
          })
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  componentWillUnmount () {
    console.log('App end');
  }
  cartAdd(type, id, nbr){
    let cart;
    console.log('cart add App');
    if(type === 0){
        cart = this.state.top_cart;
        cart[id] = nbr;
        this.setState({top_cart : cart});
    } else if (type === 1) {
        cart = this.state.shop_cart;
        cart[id] = nbr;
        this.setState({shop_cart : cart});
    }
  }
  wishAdd(type, id, status){
      let wish;
      if(type === 0){
          wish = this.state.top_cart;
          wish[id] = status;
          this.setState({top_wish : wish});
      } else if (type === 1) {
          wish = this.state.shop_cart;
          wish[id] = status;
          this.setState({shop_wish : wish});
      }
  }
  cartNumberChange(type, id, number){
    let cart;
    if(type === 0) {
      cart = this.state.shop_cart;
      cart[id] = number;
      this.setState({shop_cart : cart});
    } else if (type === 1) {
      cart = this.state.top_cart;
      cart[id] = number;
      this.setState({top_cart : cart});
    }
  }
  cartNextStep(step, data){
    if (step === 0){
      this.setState({
        zip : data.zip,
        total : data.total
      })
    } else if (step === 1){
      this.setState({
        surname : data.surname,
        name : data.name,
        address : data.address,
        address_bis : data.address_bis,
        phone : data.phone,
        mail : data.mail,
        hour : data.hour
      })
    }
  }
  render () {
    return(
      <div className="App">
        <Menu shop_articles={this.state.shop_articles} top_articles={this.state.top_articles} shop_cart={this.state.shop_cart} shop_wish={this.state.shop_wish} top_cart={this.state.top_cart} top_wish={this.state.top_wish} numberChange={this.cartNumberChange} cartNextStep={this.cartNextStep}/>
        <Body cartAdd={this.cartAdd} wishAdd={this.wishAdd} shop_articles={this.state.shop_articles} top_articles={this.state.top_articles} shop_cart={this.state.shop_cart} shop_wish={this.state.shop_wish} top_cart={this.state.top_cart} top_wish={this.state.top_wish}/>
        <Footer />
      </div>
    );
  };
}

export default App;
