import React from "react";

class CartCover extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            shop_cart_articles: [],
            cart_articles: [],
            top_cart : [],
            shop_cart : [],
            zipObj : {
                "H3A" : true,
                "H4A" : true,
                "H5A" : true,
                "H3B" : true,
                "H5B" : true,
                "H3C" : true,
                "H4C" : true,
                "H3D" : true,
                "H3E" : true,
                "H3G" : true,
                "H3H" : true,
                "H2J" : true,
                "H3J" : true,
                "H2K" : true,
                "H3K" : true,
                "H2L" : true,
                "H4M" : true,
                "H3N" : true,
                "H2P" : true,
                "H3P" : true,
                "H1R" : true,
                "H2R" : true,
                "H3R" : true,
                "H3S" : true,
                "H2T" : true,
                "H3T" : true,
                "H2V" : true,
                "H3V" : true,
                "H2W" : true,
                "H3W" : true,
                "H2X" : true,
                "H3X" : true,
                "H2Y" : true,
                "H3Y" : true,
                "H2Z" : true,
                "H3Z" : true,
                "H4Z" : true
            },
            zip : '',
            eligible : false
        }
        this.numberChange = this.numberChange.bind(this);
        this.handleZip = this.handleZip.bind(this);
    }
    componentDidMount(){
        console.log(this.props.shop_cart);
        this.setState({
            top_cart_articles : this.props.top_cart_articles,
            cart_articles : this.props.cart_articles,
            shop_cart : this.props.shop_cart,
            top_cart : this.props.top_cart
        });
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            console.log(this.props.shop_cart);
            this.setState({
                top_cart_articles : this.props.top_cart_articles,
                cart_articles : this.props.cart_articles,
                shop_cart : this.props.shop_cart,
                shop_wish : this.props.shop_wish,
            });
        }
    }
    numberChange(type, id, number) {
        let cart;
        if(parseInt(type) === 1){
            cart = this.state.top_cart;
            cart[id] = parseInt(number);
            this.setState({top_cart : cart}, () => {
                if(parseInt(number) === 0)
                    this.articleLineCreate();
                this.props.numberChange(parseInt(type), parseInt(id), parseInt(number));
            });
        } else if (parseInt(type) === 0) {
            cart = this.state.shop_cart;
            cart[id] = parseInt(number);
            this.setState({shop_cart : cart}, () => {
                if(parseInt(number) === 0)
                    this.articleLineCreate();
                this.props.numberChange(parseInt(type), parseInt(id), parseInt(number));
            });
        }
    }
    handleZip(e){
        let eligible = false
        if(this.state.zipObj[e.target.value.toUpperCase()]){
            eligible = true;
        }
        this.setState({
            zip : e.target.value,
            eligible : eligible
        }, this.props.handleEligible(eligible, e.target.value));   
    }
    render(){
        return(
            <>
                {/* <h4>Et non pas encore !</h4>
                <h5>Notre shop n'est pas encore actif, on fait au plus vite pour que vous puissiez goûter nos choux maison livrer à domicile ou à votre travail</h5> */}
                
                <h4>êtes vous éligible à la livraison ?</h4>
                <h5>Saisissez votre code postal pour vérifier :</h5>
                <input type='text' placeholder='Code postal' onChange={this.handleZip} value={this.state.zip}/><br/><br/>
                <div className={this.state.eligible === true && this.state.zip !== '' ? 'alert alert-success' : 'alert alert-danger'}>{this.state.eligible === true && this.state.zip !== ''  ? 'Vous êtes éligible' : 'Nous ne livrons pas à ce code postal'}</div>
                {this.props.top_cart_articles}
                {this.props.cart_articles}
                {this.props.total > 0 ? <div className="cart_total"><p>Total ttc : <span>{Math.round((this.props.total + Number.EPSILON) * 100) / 100} $CAD</span><br/>Taxes : <span>{Math.round((this.props.taxes + Number.EPSILON) * 100) / 100} $CAD</span></p></div> : <div className="cart_total"><p>Votre panier est vide</p></div>}
            </>
        )
    }
}

export default CartCover