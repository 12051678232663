import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useState } from "react";

export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);

  const elements = useElements();

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        address: {
          city: "Montreal",
          country: "CA",
          line1: props.user.address,
          line2: props.user.address_bis,
          postal_code: props.user.zip,
          state: "Quebec",
        },
        email: props.user.mail,
        name: props.user.surname + " " + props.user.name,
        phone: props.user.tel,
      },
    });
    if (!error) {
      console.log("Token généré : ", paymentMethod);
      // Envoie du token au serveur
      try {
        const { id } = paymentMethod;
        console.log("Token envoyé : " + id);
        console.log("Montant : " + props.total);
        const response = await axios.post(
          "https://chouxtime.com/api/stripe/charge",
          {
            articles : [
              {
                id : 'price_1M7ca7GCwwPpVWaunMkADhv1',
                nbr : 2
              }
            ],
            custommer : {
              name: props.user.surname + " " + props.user.name,
              email: props.user.mail,
              description : "Client chouxtime"
            },
            amount: Math.round((props.total + Number.EPSILON) * 100),
            taxes : props.taxes,
            taxes_tps: props.taxes_tps,
            taxes_tvq: props.taxes_tvq,
            id: id,
            order_id : props.order_id
          }
        );
        if (response.data.success === true) {
          console.log("Paiement accepté");
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          setTimeout(() => {
            console.log('Validation après paiement !');
            props.cartValid()
          }, 1000);
        }
      } catch (error) {
        console.log("Error...", error);
        setError('Payment failed', error);
        setProcessing(false);
      }
    } else {
      console.log("Payment Method error");
      console.log(error.message);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={
          {hidePostalCode : true, 
            cardStyle,
            layout: "tabs"
          }
        }
        onChange={handleChange}
      />
      <button className='btn btn-dark' disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? (
            <>Paiement en cours...<div className="spinner" id="spinner"></div></>
          ) : (
            "Valider"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? "result-message alert alert-success" : "result-message hidden"}>
        Paiement accepté !<br/>
      </p>
    </form>
  );
};

export default CheckoutForm;
