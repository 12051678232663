import React from "react";

class CartAccept extends React.Component{
    render(){
        return(
            <div>
                Votre commande a bien été accepté
            </div>
        )
    }
}

export default CartAccept;