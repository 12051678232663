import React from "react";
import './CartDelivery.css';
import axios from "axios";

class CartDelivery extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            delivery_state : 1,
            active_hour : null,
            map : <img src='#' className="map" widht='100%' height='100%' alt='map delivery'/>,
            delivery_name : "",
            delivery_surname : "",
            delivery_address : "",
            delivery_address_bis : "",
            delivery_tel : "",
            delivery_mail : "",
            deliveryHours : [3, 3, 3]
        }
        this.deliveryStateChange = this.deliveryStateChange.bind(this);
        this.changeHour = this.changeHour.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setDeliveryHours = this.setDeliveryHours.bind(this);
    }
    componentDidMount(){
        this.setDeliveryHours();
    }
    async setDeliveryHours(){
        console.log('téléchargement des horaires disponible');
        try {
            const deliveryHours = await axios.post(
                "https://chouxtime.com/api/delivery_hours"
            );
            if (deliveryHours.data.success) {
                this.setState({
                    deliveryHours : JSON.parse(deliveryHours.data.hoursList)
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    deliveryStateChange(status){
        let img;
        if(status === 0){
            img = 'boutique.jpg';
        } else {
            img = 'map.jpg';
        }
        this.setState({
            delivery_state : status,
            map : <img src={img} className="map" widht='100%' height='100%' alt='map delivery'/>,
            active_hour : 0,
            hour : []
        });
    }
    changeHour(id){
        this.setState({active_hour : id}, () => { 
            if(this.state.active_hour !== null){
                if(this.state.delivery_name.match(/^[a-zA-ZàâäéèêëïîôöùûüÿñçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÑÇ-]+$/)){
                    if(this.state.delivery_surname.match(/^[a-zA-ZàâäéèêëïîôöùûüÿñçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÑÇ-]+$/)){
                        if(this.state.delivery_address.match(/^[a-zA-Z0-9àâäéèêëïîôöùûüÿñçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÑÇ-\s]+$/)){
                            if(this.state.delivery_tel.match(/^(?:\+1|1)?(?:\s|-)?\(?([0-9]{3})\)?(?:\s|-)?([0-9]{3})(?:\s|-)?([0-9]{4})$/)){
                                if(this.state.delivery_mail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
                                    this.props.delivery_valid(this.state.delivery_name, this.state.delivery_surname, this.state.delivery_address, this.state.delivery_address_bis, this.state.delivery_tel, this.state.delivery_mail, this.state.active_hour);
                                }
                            }
                        }
                    }
                }
            } 
        });
    }
    handleChange(e){
        this.setState({
            [e.target.id] : e.target.value
        }, () => { 
            if(this.state.active_hour !== null){
                if(this.state.delivery_name.match(/^[a-zA-ZàâäéèêëïîôöùûüÿñçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÑÇ-]+$/)){
                    if(this.state.delivery_surname.match(/^[a-zA-ZàâäéèêëïîôöùûüÿñçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÑÇ-]+$/)){
                        if(this.state.delivery_address.match(/^[a-zA-Z0-9àâäéèêëïîôöùûüÿñçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÑÇ-\s]+$/)){
                            if(this.state.delivery_tel.match(/^(?:\+1|1)?(?:\s|-)?\(?([0-9]{3})\)?(?:\s|-)?([0-9]{3})(?:\s|-)?([0-9]{4})$/)){
                                if(this.state.delivery_mail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
                                    this.props.delivery_valid(this.state.delivery_name, this.state.delivery_surname, this.state.delivery_address, this.state.delivery_address_bis, this.state.delivery_tel, this.state.delivery_mail, this.state.active_hour);
                                }
                            }
                        }
                    }
                }
            } 
        });


    }
    render(){
        let delivery_hour;
        if(this.state.active_hour === 0) delivery_hour = 'vous serez livré entre 17h00 et 18h00';
        else if(this.state.active_hour === 1) delivery_hour = 'vous serez livré entre 18h00 et 19h00';
        else if(this.state.active_hour === 2) delivery_hour = 'vous serez livré entre 19h00 et 20h00';
        else delivery_hour = 'veuillez sélectionner une heure de livraison';
        return(
            <div>
                <h2>Livraison</h2>
                <div className='cart_delivery_desc'>
                    <div className='cart_delivery'>
                        <p>Pour déguster nos patisserie vous avez deux choix :</p>
                        <button className={this.state.delivery_state === 0 ? 'btn btn-primary' : 'btn btn-dark'} onClick={() => this.deliveryStateChange(0)}>Je passe les chercher de suite</button>
                        <button className={this.state.delivery_state === 1 ? 'btn btn-primary' : 'btn btn-dark'} onClick={() => this.deliveryStateChange(1)}>Je me fais livrer demain</button>
                    </div>
                    <div className='cart_map'>
                        {this.state.map}
                    </div>
                </div>
                <form>
                    <h3>Informations :</h3>
                    <div className="form-floating mb-3">
                        <input id='delivery_name' value={this.state.delivery_name} type='text' className="contact_input form-control" placeholder="Nom" onChange={this.handleChange}></input>
                        <label htmlFor="delivery_name">Nom</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input id='delivery_surname' value={this.state.delivery_surname} type='text' className="contact_input form-control" placeholder="Prénom" onChange={this.handleChange}></input>
                        <label htmlFor="delivery_surname">Prénom</label>
                    </div>
                    <div className={this.state.delivery_state === 0 ? 'hidden' : ''}>
                        <div className="form-floating mb-3">
                            <input id='delivery_address' value={this.state.delivery_address} type='text' className="contact_input form-control" placeholder="Adresse" onChange={this.handleChange}></input>
                            <label htmlFor="delivery_address">Adresse</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input id='delivery_address_bis' value={this.state.delivery_address_bis} type='text' className="contact_input form-control" placeholder="Adresse suite" onChange={this.handleChange}></input>
                            <label htmlFor="delivery_address_bis">Adresse suite</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input id='delivery_tel' value={this.state.delivery_tel} type='text' className="contact_input form-control" placeholder="Numéro de téléphone" onChange={this.handleChange}></input>
                            <label htmlFor="delivery_tel">Numéro de téléphone</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input id='delivery_mail' value={this.state.delivery_mail} type='text' className="contact_input form-control" placeholder="Adresse mail" onChange={this.handleChange}></input>
                            <label htmlFor="delivery_mail">Adresse mail</label>
                        </div>
                        
                        {/* <div className="form-floating mb-3">
                            <input id='delivery_surname' type='text' className="contact_input form-control" placeholder="Prénom"></input>
                            <label htmlFor="delivery_surname">Code postale</label>
                        </div> */}
                    </div>
                </form>
                <div className='cart_delivery_hour'>
                       <button className={this.state.deliveryHours[0] > 0 ? (this.state.active_hour === 0 ? "btn btn-primary" : "btn btn-dark") : "btn btn-dark hidden"} onClick={() => this.changeHour(0)}>17:00 à 18:00</button> 
                       <button className={this.state.deliveryHours[1] > 0 ? (this.state.active_hour === 1 ? "btn btn-primary" : "btn btn-dark") : "btn btn-dark hidden"} onClick={() => this.changeHour(1)}>18:00 à 19:00</button> 
                       <button className={this.state.deliveryHours[2] > 0 ? (this.state.active_hour === 2 ? "btn btn-primary" : "btn btn-dark") : "btn btn-dark hidden"} onClick={() => this.changeHour(2)}>19:00 à 20:00</button>
                    </div>
                    <h5>{delivery_hour}</h5>
            </div>
        )
    }
}

export default CartDelivery;