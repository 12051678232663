import React from "react";
import ShopArticle from "../ShopArticle/ShopArticle";
import "./ShopView.css";

class ShopView extends React.Component{
    constructor (props) {
        super(props);
        this.state = {
            articles : [],
            shop_articles : [],
            shop_state : {
                cart : [],
                wish : []
            },
            category : null
        }
        this.cartAdd = this.cartAdd.bind(this);
        this.wishAdd = this.wishAdd.bind(this);
        this.articleRegister = this.articleRegister.bind(this);
        this.articleCategoryRegister = this.articleCategoryRegister.bind(this);
    }
    componentDidMount() {
        var shop_state = {};
        shop_state.cart = this.props.cart;
        shop_state.wish = this.props.wish;
        
        this.setState({
            articles : this.props.articles,
            shop_state : shop_state
        }, () => this.articleRegister());
    }
    componentDidUpdate(prevProps) {
        if(this.props.category !== prevProps.category){
            if(this.props.category === null){
                this.articleRegister();
            } else {
                this.articleCategoryRegister();
            }
        }
        else if(this.props !== prevProps){
            
            var shop_state = {};
            shop_state.cart = this.props.cart;
            shop_state.wish = this.props.wish;
        
            if(prevProps !== this.props){
                this.setState({
                    articles : this.props.articles,
                    shop_state : shop_state
                }, () => this.articleRegister());
            }
        }
    }
    articleRegister(){
        var shop_articles = this.state.articles.map((article, art_i) => 
            <ShopArticle name={article.name} img={article.img} desc={article.desc} number={this.state.shop_state.cart[art_i]}  wish={this.state.shop_state.wish[art_i]} price={article.price} id={art_i} key={art_i} cartAdd={this.cartAdd} wishAdd={this.wishAdd} />
        );
        this.setState({shop_articles : shop_articles});
    }
    articleCategoryRegister(){
        var shop_articles = this.state.articles.map((article, art_i) => {
                if(article.category === this.props.category) {
                    return <ShopArticle name={article.name}  desc={article.desc}  price={article.price} id={art_i} key={art_i} cartAdd={this.cartAdd} wishAdd={this.wishAdd} />;
                } 
                return '';
            }
        );
        this.setState({shop_articles : shop_articles});
        
    }
    cartAdd(id, nbr, status){
        var shop_state = this.state.shop_state;
        if(status === 0) {
            shop_state.cart[id] = nbr;
        } else {
            shop_state.cart[id] = 0;
            nbr = 0;
        }
        this.setState({shop_state : shop_state}, this.props.cartAdd(1, id, nbr));
    }
    wishAdd(id, status){
        var shop_state = this.state.shop_state;
        if(status === 0) {
            shop_state.wish[id] = 1;
        } else {
            shop_state.wish[id] = 0;
        }
        this.setState({shop_state : shop_state}, this.props.wishAdd(1, id, status));
    }
    render() {
        return(
            <div className='shop_view container-fluid' id='store'>
                <div className="container">
                    <h3>Boutique</h3>
                    <div className="shop_view_articles">
                        { this.state.shop_articles }
                    </div>
                </div>
            </div>
        )
    }
}

export default ShopView;
