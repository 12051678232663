import React from "react";
import './Menu.css';
import Connexion from "./Connexion/Connexion";
import Inscription from "./Inscription/Inscription";
import Cart from "./Cart/Cart";

class Menu extends React.Component{
    constructor (props) {
        super(props);
        this.state = {
            shop_articles : [],
            top_articles : [],
            shop_cart : [],
            shop_wish : [],
            top_cart : [],
            top_wish : [],
            phoneMenu : 0
        }
        this.numberChange = this.numberChange.bind(this);
        this.active_phone_menu = this.active_phone_menu.bind(this);
    }
    componentDidMount(){
        let total_article = 0;

        for (let i = 0; i < this.props.shop_cart.length; i++) {
            total_article += this.props.shop_cart[i];
        }
        for (let i = 0; i < this.props.top_cart.length; i++) {
            total_article += this.props.top_cart[i];
        }
        this.setState({
            shop_articles : this.props.shop_articles,
            top_articles : this.props.top_articles,
            shop_cart : this.props.shop_cart,
            shop_wish : this.props.shop_wish,
            top_cart : this.props.top_cart,
            top_wish : this.props.top_wish,
            total_article : total_article
        });
    }
    componentDidUpdate(prevProps){     
        if(prevProps !== this.props){
            console.log('Menu update');
            let total_article = 0;
            for (let i = 0; i < this.props.shop_cart.length; i++) {
                total_article += this.props.shop_cart[i];
            }
            for (let i = 0; i < this.props.top_cart.length; i++) {
                total_article += this.props.top_cart[i];
            }
            console.log(total_article);
            console.log(this.props.top_articles);
            console.log(this.props.top_cart);

            this.setState({
                shop_articles : this.props.shop_articles,
                top_articles : this.props.top_articles,
                shop_cart : this.props.shop_cart,
                shop_wish : this.props.shop_wish,
                top_wish : this.props.top_wish,
                top_cart : this.props.top_cart,
                total_article : total_article
            });
        }
    }
    numberChange(type, id, number){
        let cart;
        let total_article = 0;

        if(type === 0) {
            cart = this.state.shop_cart;
            cart[id] = number;

            for (let i = 0; i < cart.length; i++) {
                total_article += cart[i];
            }
            for (let i = 0; i < this.state.top_cart.length; i++) {
                total_article += this.state.top_cart[i];
            }

            this.setState({shop_cart : cart, total_article: total_article}, this.props.numberChange(type, id, number));
        } else if (type === 1) {
            cart = this.state.top_cart;
            cart[id] = number;

            for (let i = 0; i < this.state.shop_cart.length; i++) {
                total_article += this.state.shop_cart[i];
            }
            for (let i = 0; i < cart.length; i++) {
                total_article += cart[i];
            }

            this.setState({top_cart : cart, total_article: total_article}, this.props.numberChange(type, id, number));
        }
    }
    active_phone_menu(){
        this.setState({
            phoneMenu : this.state.phoneMenu === 0 ? 1 : 0
        })
    }
    render() {
        return (
            <>
                <div className="menu fixed_menu">
                    <nav className="container menu">
                        <ul className="classic_nav nav justify-content-center">
                            <li className="nav-item"><a href='#whoarewe' className="nav-link">Who are we ?</a></li>
                            <li className="nav-item"><a href='#productoftheweek' className="nav-link">Product of the week</a></li>
                            <li className="nav-item"><a href='#store' className="nav-link">Store</a></li>
                            <li className="nav-item"><a href='#contact' className="nav-link">Contact</a></li>
                        </ul>
                        <div className={this.state.phoneMenu === 0 ? "menu_burger burger_inactive" : "menu_burger burger_active"} onClick={this.active_phone_menu}>
                            <div className='burger_line burger_line_1'></div>
                            <div className='burger_line burger_line_2'></div>
                            <div className='burger_line burger_line_3'></div>
                        </div>
                        <div className='menu_logo nav-item' alt='Choux Time logo'></div>
                        <ul  className="nav justify-content-center">
                            <li className="nav-item"><button className='cart_button btn btn-light' data-bs-toggle="modal" data-bs-target="#cartBackdrop"> <img src={require('./img/cart_icon.png')} alt='choux time cart icone' className="menu_cart_icon"></img><span className='cart_button_nbr'>{this.state.total_article}</span></button></li>
                            {/* <li className="nav-item"><button className="nav-link btn" data-bs-toggle="modal" data-bs-target="#suscribeBackdrop">S'inscrire</button></li>
                            <li className="nav-item"><button className="btn btn-success"  data-bs-toggle="modal" data-bs-target="#connecBackdrop">Se connecter</button></li> */}
                        </ul>
                    </nav>
                </div>
                <div className={this.state.phoneMenu === 0 ? 'phone_nav' : 'phone_nav phone_nav_active'}>
                    <ul className="phone-nav-ul">
                        <li className="phone-nav-item"><a href='#whoarewe' className="nav-link" onClick={this.active_phone_menu}>Who are we ?</a></li>
                        <li className="phone-nav-item"><a href='#productoftheweek' className="nav-link" onClick={this.active_phone_menu}>Product of the week</a></li>
                        <li className="phone-nav-item"><a href='#store' className="nav-link" onClick={this.active_phone_menu}>Store</a></li>
                        <li className="phone-nav-item"><a href='#contact' className="nav-link" onClick={this.active_phone_menu}>Contact</a></li>
                    </ul>
                </div>
                <Cart shop_articles={this.state.shop_articles} top_articles={this.state.top_articles} shop_cart={this.state.shop_cart} shop_wish={this.state.shop_wish} top_cart={this.state.top_cart} top_wish={this.state.top_wish} numberChange={this.numberChange} cartNextStep={() => this.props.cartNextStep()}></Cart>
                <Connexion></Connexion>
                <Inscription></Inscription>
            </>
        )
    }
}

export default Menu;