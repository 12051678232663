import React from "react";
import ShopTop from "./ShopTop/ShopTop";
// import ShopFilter from "./ShopFilter/ShopFilter";
import ShopView from "./ShopView/ShopView";

class Shop extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            category : null,
            shop_articles: [],
            top_articles: [],
            shop_cart : [0, 0, 0, 0, 0],
            shop_wish : [0, 0, 0, 0, 0],
            top_cart : [0, 0, 0],
            top_wish : [0, 0, 0]
        }
        this.cartAdd = this.cartAdd.bind(this);
        this.wishAdd = this.wishAdd.bind(this);
    }
    componentDidMount(){
        this.setState({
            shop_articles : this.props.shop_articles,
            top_articles : this.props.top_articles,
            shop_cart : this.props.shop_cart,
            shop_wish : this.props.shop_wish,
            top_cart : this.props.top_cart,
            top_wish : this.props.top_wish
        });
    }
    componentDidUpdate(prevProps){
        if(this.props.shop_articles !== prevProps.shop_articles || this.props.top_articles !== prevProps.top_articles){
            this.setState({
                shop_articles : this.props.shop_articles,
                top_articles : this.props.top_articles,
                shop_cart : this.props.shop_cart,
                shop_wish : this.props.shop_wish,
                top_cart : this.props.top_cart,
                top_wish : this.props.top_wish
            });
        }
    }
    cartAdd(type, id, nbr){
        let cart;
        if(type === 0){
            cart = this.state.top_cart;
            cart[id] = nbr;
            this.setState({top_cart : cart}, this.props.cartAdd(type, id, nbr));
        } else if (type === 1) {
            cart = this.state.shop_cart;
            cart[id] = nbr;
            this.setState({shop_cart : cart}, this.props.cartAdd(type, id, nbr));
        }
    }
    wishAdd(type, id, status){
        let wish;
        if(type === 0){
            wish = this.state.top_cart;
            wish[id] = status;
            this.setState({top_wish : wish}, this.props.wishAdd(type, id, status));
        } else if (type === 1) {
            wish = this.state.shop_cart;
            wish[id] = status;
            this.setState({shop_wish : wish}, this.props.wishAdd(type, id, status));
        }
    }
    render() {
        return (
            <div className='shop'>
                <ShopTop articles={this.state.top_articles} cartAdd={this.cartAdd} wishAdd={this.wishAdd} cart={this.state.top_cart} wish={this.state.top_wish}/>
                {/* <ShopFilter/> */}
                <ShopView articles={this.state.shop_articles} category={this.state.category} cartAdd={this.cartAdd} wishAdd={this.wishAdd} cart={this.state.shop_cart} wish={this.state.shop_wish}/>
            </div>
        )
    }
}

export default Shop;