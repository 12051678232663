import React from "react";
import './Cover.css';

class Cover extends React.Component {
    render() {
        return (
            <div className="cover container-fluid">
                <div className="container">
                    <div className='cover_text col-6'>
                        <h1>It's choux time !</h1>
                        <p>
                            Choux time c'est votre patissier français directement dans Montréal,<br/>
                            des patisseries de qualités, un savoir-faire unique et par dessus tout une spécialitée... le choux.<br/><br/>
                            Nous venons de nous installer et les commandes ne sont pas encore disponible mais elles le sauront dans quelques jours.

                        </p>
                        <button className="btn btn-dark">En savoir plus</button>
                    </div>
                    <div className='cover_image col-5'></div>
                </div>
            </div>
        )
    }
}

export default Cover;
