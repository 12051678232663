import React from "react";
import "./WhoWeAre.css";

class WhoWeAre extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            title : [
                "La touche française",
                "On vous livre ?",
                "Ou déguster nos choux ?",
                "Des choux pour un événement ?"
            ],
            text : [
                <>Le savoir-faire français prêt de chez vous.<br/> De vrai choux français sans même avoir à prendre l'avion, c'est que que nous pouvons vous promettre.</>,
                <>Pour toute livraison pensez à commander 24h à l'avance, nous vous livrons dans Montréal, vérifier que votre rue est éligible à la livraison lors de la validation de votre panier. <br/><br/> Si vous ne pouvez pas vous faire livrer ou que vous souhaitez déguster nos patisserie immédiatement rendez-vous à : <br/></>,
                <>On a pas encore notre boutique mais ça ne saurait tarder !<br/>Si on ne peut pas vous livrer vous pouvez retrouvez certains de nos choux chez  : <br/><br/> Nom du restaurant <br/> Adresse <br/> Adresse bis <br/> 00 000 Ville </>,
                <>Pour tous types d'événement (mariages, naissance, anniversaire...) n'hésitez pas à nous contacter grâce au formulaire en bas de page.<br/> Nous vous proposerons un tarif sur mesure dans les plus brefs délais</>
            ],
            contentId : 1
        }
        this.changeContent = this.changeContent.bind(this);
    }
    
    changeContent(id){
        this.setState({contentId : id})
    }
    render() {
        return(
            <div className='who_we_are container' id='whoarewe'>
                <div className='text_switch_bar'>
                    <div className='text_switch' onClick={() => this.changeContent(1)}><div alt='categorie' className='text_switch_image text_switch_image_2'></div><h2>LIVRAISON</h2></div>
                    <div className='text_switch' onClick={() => this.changeContent(0)}><div alt='categorie' className='text_switch_image text_switch_image_1'></div><h2>ARTISANAT</h2></div>
                    <div className='text_switch' onClick={() => this.changeContent(2)}><div alt='categorie' className='text_switch_image text_switch_image_3'></div><h2>BOUTIQUE</h2></div>
                    <div className='text_switch' onClick={() => this.changeContent(3)}><div alt='categorie' className='text_switch_image text_switch_image_4'></div><h2>ÉVÉNEMENTS</h2></div>
                </div>
                <div className='who_we_are_text'>
                    <h3>{this.state.title[this.state.contentId]}</h3>
                    <p>
                        {this.state.text[this.state.contentId]}
                    </p>
                </div>
            </div>
        )
    }
}

export default WhoWeAre;