import React from "react";
import Stripe from "../../../Stripe/StripeContainer";
class CartPaiement extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            shop_articles : [],
            top_articles : [],
            shop_cart : [],
            top_cart : [],
            user : {},
            total : 0,
            taxes : 0,
            taxes_tps : 0,
            taxes_tvq : 0,
            order_id : 0
        }
        this.cartValid = this.cartValid.bind(this);
    }
    componentDidMount(){
        console.log(this.props.total);
        this.setState({
            shop_articles : this.props.shop_articles,
            top_articles : this.props.top_articles,
            shop_cart : this.props.shop_cart,
            top_cart : this.props.top_cart,
            user : this.props.user,
            total : this.props.total,
            taxes : this.props.taxes,
            taxes_tps : this.props.taxes_tps,
            taxes_tvq : this.props.taxes_tvq,
            order_id : this.props.order_id
        });
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            console.log(this.props.total);
            this.setState({
                shop_articles : this.props.shop_articles,
                top_articles : this.props.top_articles,
                shop_cart : this.props.shop_cart,
                top_cart : this.props.top_cart,
                user : this.props.user,
                total : this.props.total,
                taxes : this.props.taxes,
                taxes_tps : this.props.taxes_tps,
                taxes_tvq : this.props.taxes_tvq,
                order_id : this.props.order_id
            });
        }
    }
    cartValid(){
        console.log('Cart Paiement Valid');
        this.props.cartValid();
    }
    render(){
        console.log(this.state.total);
        return(
            <div>
                <h4>Paiement par carte bleu</h4><br/><br/>
                <Stripe 
                    shop_articles={this.state.shop_articles} 
                    top_articles={this.state.top_articles} 
                    shop_cart={this.state.shop_cart} 
                    top_cart={this.state.top_cart} 
                    user={this.state.user}
                    total={this.state.total}
                    taxes={this.state.taxes}
                    taxes_tps={this.state.taxes_tps}
                    taxes_tvq={this.state.taxes_tvq}
                    order_id={this.state.order_id}
                    cartValid={this.cartValid}
                />
            </div>
        )
    }
}

export default CartPaiement;