import React from "react";
import "./Contact.css";
import axios from "axios";

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            contact_name : '',
            contact_surname : '',
            contact_tel : '',
            contact_mail : '',
            contact_message : '',
            alert : null,
            alert_input : null,
            message_sent : false
        }
        this.handleData = this.handleData.bind(this);
        this.sendContact = this.sendContact.bind(this);
    }
    handleData(e){
        this.setState({[e.target.id] : e.target.value});
    } 
    async sendContact(e){
        e.preventDefault();
        if(this.state.contact_name === ''){
            this.setState({
                alert : 'Veuillez saisir un nom correct',
                alert_input : 0
            });
        } else if (this.state.contact_surname === '') {
            this.setState({
                alert : 'Veuillez saisir un prénom correct',
                alert_input : 1
            });
        } else if (this.state.contact_tel !== '' && !this.state.contact_tel.match(/^(?:(?:\+|00)1[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)) {
            this.setState({
                alert : 'Le numero de téléphone saisie est incorrect',
                alert_input : 2
            });
        } else if (this.state.contact_mail === '') {
            this.setState({
                alert : 'Veuillez saisir une adresse mail correct',
                alert_input : 3
            });
        } else if (this.state.contact_message === '') {
            this.setState({
                alert : 'Veuillez saisir un message',
                alert_input : 4
            });
        } else {
            this.setState({
                alert : null,
                alert_input : null
            });
            try{
                const contact = await axios.post(
                    "https://chouxtime.com/api/contact",
                    {
                        name : this.state.contact_name,
                        surname : this.state.contact_surname,
                        tel : this.state.contact_tel,
                        mail : this.state.contact_mail,
                        message : this.state.contact_message,
                    }
                );
                if (contact.data.success) {
                    this.setState({message_sent : true});
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    render() {
        return(
            <div id="contact" className='contact_fluid container-fluid'>
                <div className='contact container'>
                    <div className="contact_img ratio ratio-1x1"></div>
                    <div className="contact_form">
                        <h3>Nous contacter</h3>
                        {this.state.message_sent === false ? 
                            <>
                                <form>
                                    <div className="form-floating mb-3">
                                        <input id='contact_name' type='text' className="contact_input form-control" placeholder="Nom" onChange={this.handleData}></input>
                                        <label htmlFor="contact_name">Nom</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input id='contact_surname' type='text' className="contact_input form-control" placeholder="Prénom" onChange={this.handleData}></input>
                                        <label htmlFor="contact_surname">Prénom</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input id='contact_tel' type='text' className="contact_input_large form-control" placeholder="Téléphone" onChange={this.handleData}></input>
                                        <label htmlFor="contact_tel">Téléphone</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input id='contact_mail' type='text' className="contact_input_large form-control" placeholder="Mail*" onChange={this.handleData}></input>
                                        <label htmlFor="contact_mail">Mail*</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <textarea id='contact_message' className="contact_message form-control" placeholder="Votre message*" onChange={this.handleData}></textarea>
                                        <label htmlFor="contact_message">Votre message...*</label>
                                    </div>
                                    <div className={this.state.alert !== null ? "alert alert-danger" : ""}>
                                        {this.state.alert}
                                    </div>
                                    <button className="btn btn-secondary" onClick={this.sendContact}>Envoyer</button>
                                </form>
                            </> : <h4>Nous venons de recevoir votre message et vos coordonnées,<br/> nous vous recontactons au plus vite</h4>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;