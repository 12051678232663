import React from "react";
import Cover from "./Cover/Cover";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import Shop from "./Shop/Shop";
import Contact from "./Contact/Contact";

class Body extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            shop_articles : [],
            top_articles : [],
            shop_cart : [],
            shop_wish : [],
            top_cart : [],
            top_wish : []
        }
        this.wishAdd = this.wishAdd.bind(this);
        this.cartAdd = this.cartAdd.bind(this);
    }
    componentDidMount(){
        this.setState({
            shop_articles : this.props.shop_articles,
            top_articles : this.props.top_articles,
            shop_cart :  this.props.shop_cart,
            shop_wish :  this.props.shop_wish,
            top_cart :  this.props.top_cart,
            top_wish :  this.props.top_wish
        });
    }
    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.setState({
                shop_articles : this.props.shop_articles,
                top_articles : this.props.top_articles,
                shop_cart :  this.props.shop_cart,
                shop_wish :  this.props.shop_wish,
                top_cart :  this.props.top_cart,
                top_wish :  this.props.top_wish
            });
        }
    }
    cartAdd(type, id, nbr){
        let cart;
        console.log('cart add in body')
        if(type === 0){
            cart = this.state.top_cart;
            cart[id] = nbr;
            this.setState({top_cart : cart}, this.props.cartAdd(type, id, nbr));
        } else if (type === 1) {
            cart = this.state.shop_cart;
            cart[id] = nbr;
            this.setState({shop_cart : cart}, this.props.cartAdd(type, id, nbr));
        }
    }
    wishAdd(type, id, status){
        let wish;
        if(type === 0){
            wish = this.state.top_wish;
            wish[id] = status;
            this.setState({top_wish : wish}, this.props.wishAdd(type, id, status));
        } else if (type === 1) {
            wish = this.state.shop_wish;
            wish[id] = status;
            this.setState({shop_wish : wish}, this.props.wishAdd(type, id, status));
        }
    }
    render() {
        return (
            <div className='main_container'>
                <Cover />
                <WhoWeAre />
                <Shop wishAdd={this.wishAdd} cartAdd={this.cartAdd} shop_cart={this.state.shop_cart} shop_wish={this.state.shop_wish} top_cart={this.state.top_cart} top_wish={this.state.top_wish} shop_articles={this.state.shop_articles} top_articles={this.state.top_articles}/>
                <Contact />
            </div>
        )
    }
}

export default Body;