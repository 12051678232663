import React from "react";

class Connexion extends React.Component{
    render () {
        return(
            <div className="modal fade" id="connecBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header bg-light">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Se connecter</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-floating mb-3">
                                <input id='connec_mail' type='mail' className="contact_input form-control" placeholder="Mail"></input>
                                <label htmlFor="connec_mail">Mail</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input id='connec_pass' type='password' className="contact_input form-control" placeholder="Mot de passe"></input>
                                <label htmlFor="connec_pass">Mot de passe</label>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" className="btn btn-primary">Connexion</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Connexion;